// Had to add parsley to the JS files seperately.
// Because they were called like this "form.parsley().validate()" and this fails with parsley is not a function.
import "parsleyjs/dist/parsley.min.js";
import "./companySearchAutoComplete.js";
import { addCounterToEveryTextArea } from "./forms";
import { processExternalLinks } from "./externalLink";
import { Tooltip } from "./tooltip";

export function toggleButtons(enable, form) {
    let formButtons = form.find(".form-buttons");
    if (formButtons.length > 0) {
        formButtons.find(".btn").each(function () {
            let $button = $(this);
            if (enable) {
                $button.prop('disabled', false);
                $button.removeClass('disabled');
            }
            else {
                $button.prop('disabled', true);
                $button.addClass('disabled');
            }
        })

    }
}

export function toggleSpinner(show, form) {
    let spinnerEl = form.find(".form-element__spinner");
    if (spinnerEl.length > 0) {
        if (show) {
            spinnerEl.show();
        }
        else {
            spinnerEl.hide();
        }
    }
}

export function failed(form, result) {
    let status = result.status;
    let responseJSON = result.responseJSON;

    if (responseJSON && responseJSON.Deployment) {
        let deploymentMsg = form.find(".alert-deployment");
        if (deploymentMsg.length > 0) {
            deploymentMsg.show();
            setTimeout(function () {
                deploymentMsg.hide();
            }, 20000);
        }
    }
    else {
        if (status === 400) {
            let validationMsg = form.find(".alert-danger-validation");
            if (validationMsg.length > 0) {
                let errors = "";
                for (let key in responseJSON) {
                    if (responseJSON.hasOwnProperty(key)) {
                        let value = responseJSON[key];
                        errors += "<br>" + key + ": " + value;
                    }
                }
                
                let alertMsg = form.find(".alert-danger-validation-msg");
                let alertMsgHTML = alertMsg.html();

                let index = alertMsgHTML.indexOf('<br>'); // Find the index of the first <br> tag
                if (index !== -1) { // Check if <br> tag exists
                    alertMsgHTML = alertMsgHTML.substring(0, index); // Extract text before the <br> tag
                }
                
                let newAlertMsg = alertMsgHTML + errors;

                alertMsg.html(newAlertMsg);                
                validationMsg.show();                
            }
        }
        else {
            let dangerEl = form.find(".alert-danger-save");
            if (dangerEl.length > 0) {
                dangerEl.show();
                setTimeout(function () {
                    dangerEl.hide();
                }, 10000);
            }
        }
    }
}

export function saveForLater(formId) {
    let form = $('#' + formId);
    if (form.length > 0) {
        let dataForm = new FormData(form[0]);
        dataForm.append("ValidateData", false); //console.log('dataForm', dataForm.entries());

        $.ajax({
            url: form.attr("action"),
            type: 'POST',
            enctype: 'multipart/form-data',
            contentType: false,
            data: dataForm,
            processData: false,
            beforeSend: function () {
                // Disable buttons
                toggleButtons(false, form);
                // Show the spinner
                toggleSpinner(true, form);
            }
        })
            .done(function () {
                // Show the success message and hide it after 10 seconds.
                let successEl = form.find(".alert-success-save");
                if (successEl.length > 0) {
                    successEl.show();
                    setTimeout(function () {
                        successEl.hide();
                    }, 10000);
                }
            })
            .fail(function (result) {
                failed(form, result);
            })
            .always(function () {
                // Hide the spinner
                toggleSpinner(false, form);
                // Enable buttons
                toggleButtons(true, form);
            });
    }
}

export function goToStep(nextTabNbr) {
    let currentTabNbr = nextTabNbr - 1;
    let currentForm = $('#jsCompanyFormTab' + currentTabNbr);
    let nextForm = $('#jsCompanyFormTab' + nextTabNbr);
    let currentStep = $('#step' + currentTabNbr);
    let nextStep = $('#step' + nextTabNbr);
    let inactiveStepClass = 'form-progress__step';
    let activeStepClass = 'form-progress__step form-progress__step--active';
    let inactiveAttribute = 'aria-current';
    let validationMsg = currentForm.find(".alert-danger-validation");

    currentForm.parsley().validate();
    let isFormValid = currentForm.parsley().isValid();

    if (currentForm.length > 0 && isFormValid) {
        let dataForm = new FormData(currentForm[0]);
        dataForm.append("ValidateData", true);

        $.ajax({
            url: currentForm.attr("action"),
            type: 'POST',
            enctype: 'multipart/form-data',
            contentType: false,
            data: dataForm,
            processData: false,
            beforeSend: function () {
                // Disable buttons
                toggleButtons(false, currentForm);
                // Show the spinner
                toggleSpinner(true, currentForm);
            }
        })
            .done(function (result) {
                currentForm.hide();
                validationMsg.hide();
                if (nextForm.length > 0) {
                    // Is the next tab the preview?
                    if (nextTabNbr === 5) {
                        // A partial view was returned so we need to set the html
                        // console.log('html:', result);
                        let html = $('<div />').append(result);
                        //nextForm.html(result);
                        let homeTabContainer = nextForm.find('#home');
                        let homeData = html.find('.company-preview__company-page');
                        if (homeTabContainer.length > 0 && homeData.length > 0) {
                            homeTabContainer.html(homeData);
                        }
                        let cardTabContainer = nextForm.find('#profile');
                        let cardData = html.find('.company-preview__company-card')
                        if (cardTabContainer.length > 0 && cardData.length > 0) {
                            cardTabContainer.html(cardData);
                        }
                        let contactTabContainer = nextForm.find('#contact');
                        let contactData = html.find('.company-preview__company-slide')
                        if (contactTabContainer.length > 0 && contactData.length > 0) {
                            contactTabContainer.html(contactData);
                        }
                        // process external links again for preview page eg. company page contact info details.
                        processExternalLinks("Will move to new service");
                    }
                    nextForm.show();
                }
                if (currentStep.length > 0) {
                    currentStep.removeClass(activeStepClass);
                    currentStep.addClass(inactiveStepClass);
                    currentStep.removeAttr(inactiveAttribute);
                }
                if (nextStep.length > 0) {
                    nextStep.removeClass(inactiveStepClass);
                    nextStep.addClass(activeStepClass);
                    nextStep.attr("aria-current", "step");
                }
                // scroll to top after successful step change
                window.scroll(0, 0);
            })
            .fail(function (result) {
                failed(currentForm, result);
            })
            .always(function () {
                // Hide the spinner
                toggleSpinner(false, currentForm);
                // Enable buttons
                toggleButtons(true, currentForm);
                // Let's make sure that submit messages in Tab 5 are hidden by default.
                if (nextTabNbr === 5) {
                    $('#requestForPublishMsg').hide();
                    $('#requestForPublishErrorMsg').hide();
                }
            });
    } else if (!isFormValid) {
        let parsleyErrors = $('.parsley-errors-list.filled');
        if (parsleyErrors && parsleyErrors.length > 0) {
            parsleyErrors.get(0).scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
        }
    }
}

export function goToPreviousStep(previousTabNbr) {
    let currentTabNbr = previousTabNbr + 1;
    let currentForm = $('#jsCompanyFormTab' + currentTabNbr);
    let previousForm = $('#jsCompanyFormTab' + previousTabNbr);
    let currentStep = $('#step' + currentTabNbr);
    let previousStep = $('#step' + previousTabNbr);
    let inactiveAttribute = 'aria-current';
    let validationMsg = currentForm.find(".alert-danger-validation");

    if (currentForm.length > 0 && previousForm.length > 0) {
        let inactiveStepClass = 'form-progress__step';
        let activeStepClass = 'form-progress__step form-progress__step--active';
        currentForm.hide();
        validationMsg.hide();
        previousForm.show();
        if (currentStep.length > 0) {
            currentStep.removeClass(activeStepClass);
            currentStep.addClass(inactiveStepClass);
            currentStep.removeAttr(inactiveAttribute);
        }
        if (previousStep.length > 0) {
            previousStep.removeClass(inactiveStepClass);
            previousStep.addClass(activeStepClass);
            previousStep.attr("aria-current", "step");
        }
        // scroll to top when going to previous step
        window.scroll(0, 0);
        return false;
    }
}

$(function () {
    if (!$('.company-form-page').length) {
        return;
    }

    // split is already a function in JS. Renaming it to avoid possible conflict.
    function customSplit(val) {
        return val.split(/,\s*/);
    }
    function extractLast(term) {
        return customSplit(term).pop();
    }

    // Add aria-current to step1
    $('#step1').attr("aria-current", "step");

    // Prevent parsley from adding parsley-error and parsley-success classes


    $('.js-keyword-autocomplete').each(function (index) {
        let $autocompleteEl = $(this);
        let storeName = $autocompleteEl.data("store");
        $.get("/KeywordApi/get?storeName=" + storeName)
            .done(function (data) {
                let keywords = data.keywords.split(',');
                $autocompleteEl
                    .on("keydown", function (event) {
                        if (event.keyCode === $.ui.keyCode.TAB &&
                            $(this).autocomplete("instance").menu.active) {
                            event.preventDefault();
                        }
                    })
                    .autocomplete({
                        minLength: 1,
                        source: function (request, response) {
                            let responseData = $.ui.autocomplete.filter(
                                keywords, extractLast(request.term));
                            // take the first 10 elements intead of all of them. There can be thousands.
                            let filteredResponse = responseData.slice(0, 10);
                            response(filteredResponse);
                        },
                        focus: function () {
                            // prevent value inserted on focus
                            return false;
                        },
                        select: function (event, ui) {
                            let terms = customSplit(this.value);
                            // remove the current input
                            terms.pop();
                            // add the selected item
                            terms.push(ui.item.value);
                            // add placeholder to get the comma-and-space at the end
                            terms.push("");
                            this.value = terms.join(", ");
                            return false;
                        }
                    });
            })
            .fail(function () {
                return "";
            });
    });

    $('#requestPublish').on('click', function (e) {
        e.preventDefault();
        let form5Submit = $('#jsCompanyFormTab5Submit');
        let form5PreviewData = $('.company-preview__content-container');
        let $requestMsgEl = $('#requestForPublishMsg');
        let $errorMsgEl = $('#requestForPublishErrorMsg');
        if (form5Submit.length > 0) {
            let dataForm5 = new FormData(form5Submit[0]);

            $.ajax({
                url: form5Submit.attr("action"),
                type: 'POST',
                enctype: 'multipart/form-data',
                contentType: false,
                data: dataForm5,
                processData: false,
                beforeSend: function () {
                    // Disable buttons
                    toggleButtons(false, form5Submit);
                    // Show the spinner
                    toggleSpinner(true, form5Submit);
                    // Make sure error message is hidden
                    if ($errorMsgEl.length > 0) {
                        $errorMsgEl.hide();
                    }
                }
            })
                .done(function () {
                    form5Submit.hide();
                    form5PreviewData.hide();
                    if ($requestMsgEl.length > 0) {
                        $requestMsgEl.show();
                    }
                })
                .fail(function () {
                    if ($errorMsgEl.length > 0) {
                        $errorMsgEl.show();
                    }
                })
                .always(function () {
                    // Hide the spinner
                    toggleSpinner(false, form5Submit);
                    // Enable buttons
                    toggleButtons(true, form5Submit);
                });
        }
        return false;
    });


    $('#btnAddValueProposition').on('click', function (e) {
        e.preventDefault();
        let container = $('#valuePropositionContainer');
        let id = 'ValuePropositions-0';
        let idKeyoffering = "SelectedValuePropositionsKeyOfferings-0"
        let $lastProposition = container.find('.form-element__textarea-container').last();
        if ($lastProposition.length > 0) {
            let $textarea = $lastProposition.find('textarea');
            if ($textarea.length > 0) {
                id = $textarea.attr('id') + "_1";
            }
        }

        let $lastkeyoffering = container.find('.form-element__select-container').last();
        if ($lastkeyoffering.length > 0) {
            let $select = $lastkeyoffering.find('select');
            if ($select.length > 0) {
                idKeyoffering = $select.attr('id') + "_1";
            }
        }

        // Have to do the tooltip content like this to preserve html tags for some reason. Without this they get escaped.
        let addedContainerId = "addedContainer_" + id;
        var tooltipContent = null;
        var aiTooltipContent = null;

        // using native JS to ensure the element is not added to the dom and is deleted afterwards.
        if (pageTranslations && (pageTranslations.tooltip || pageTranslations.aitooltip)) {
            var textareaEl = document.createElement("textarea");

            if (pageTranslations.tooltip) {
                textareaEl.innerHTML = pageTranslations.tooltip;
                tooltipContent = textareaEl.value;
            }
            if (pageTranslations.aitooltip) {
                textareaEl.innerHTML = pageTranslations.aitooltip;
                aiTooltipContent = textareaEl.value;
            }
            textareaEl.remove();
        }

        let keyOfferingDataHtml = '';
        if (pageTranslations.keyofferingData) {
            let keyofferingDataJson = JSON.parse(pageTranslations.keyofferingData);
            for (let i = 0; i < keyofferingDataJson.length; i++) {
                let offeringData = keyofferingDataJson[i];
                let valueOption = offeringData !== null &&
                    offeringData.contentLink !== null &&
                    offeringData.contentLink.id !== null ? offeringData.contentLink.id.toString() : "";
                let textOption = offeringData !== null &&
                    offeringData.name !== null ? offeringData.name : "";
                keyOfferingDataHtml += `<option value="${valueOption}">${textOption}</option>`
            }
        }

        let contentToAddDropdown = `<div class="form-element">
                <div class="form-element__select-container">
                    <select aria-label="${pageTranslations.keyoffering}" name="SelectedValuePropositionsKeyOfferings" id="${idKeyoffering}" class="form-element__select no-label">
                        <option value="">${pageTranslations.keyoffering}</option>
                        ${keyOfferingDataHtml ? keyOfferingDataHtml : ""}
                    </select>
                </div>
            </div>`;

        let contentToAdd = $(`<div class="form-element form-element-delete" id="${addedContainerId}">
            <div class="fl-wrap fl-wrap-textarea fl-is-required fl-is-active">
                <div class="form-element"> 
                    <div class="form-element__textarea-container">
                        <label class="form-element__label fl-label" for="${id}">${pageTranslations.valueProposition}*</label>
                        <textarea class="form-element__textarea_with_delete fl-textarea" name="ValuePropositions" id="${id}" required maxlength="250" rows="4" data-parsley-error-message="${pageTranslations.validationError.valueproposition}" data-parsley-trigger="focusout" placeholder="${pageTranslations.valueProposition}*"></textarea>
                        <button type="button" aria-label="${pageTranslations.deleteValueProposition}" class="form-element__delete form-element__textarea-delete"></button>
                    </div>
                </div>
                ${contentToAddDropdown ? contentToAddDropdown : ""}
                ${tooltipContent ? tooltipContent : ""}
                ${aiTooltipContent ? aiTooltipContent : ""}
           </div>
        </div>`);


        container.append(contentToAdd);
        addCounterToEveryTextArea();
        window.buildFloatLabels();
                
        var containerJS = document.getElementById(addedContainerId);
        var addValueToggles = null

        if (containerJS) {
            addValueToggles = containerJS.querySelectorAll('[data-tooltip-toggle]');
        }

        if (addValueToggles) {
            new Tooltip(addValueToggles);
        }
  
        return false;
    })

    $('#btnAddPerson').on('click', function (e) {
        e.preventDefault();
        let container = $('#addPersonBtnContainer');
        let count = 0;
        let lastPerson = $('.form-element-person').last();
        if (lastPerson.length > 0) {
            let lastIndex = lastPerson.find('.index');
            if (lastIndex.length > 0) {
                count = Number(lastIndex.val() + 1);
            }
        }
        let phonePattern = $("#CompanyForm-PhonePattern").val();
        let phoneNumberMaxLength = $("#CompanyForm-PhoneNumberMaxLength").val();

        let contentToAdd = $(`<div class="form-element-person form-element-delete">
        <div class="row">
            <div class="col-lg-7 form-element">
                <h4>${pageTranslations.contactPerson}</h4>
                <button type="button" aria-label="${pageTranslations.deleteContactPerson}" class="form-element__delete form-element__element-delete"></button>
            </div>
        </div>
        <input type="hidden" class="index" name="Persons.Index" value="${count}" />
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__FirstName">${pageTranslations.firstname}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__FirstName" name="Persons[${count}].FirstName" type="text" value="" placeholder="${pageTranslations.firstname}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__LastName">${pageTranslations.lastname}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__LastName" name="Persons[${count}].LastName" type="text" value="" placeholder="${pageTranslations.lastname}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__Title">${pageTranslations.title}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__Title" name="Persons[${count}].Title" type="text" value="" placeholder="${pageTranslations.title}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__Phone">${pageTranslations.phone}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__Phone" name="Persons[${count}].Phone" type="text" value="" placeholder="${pageTranslations.phone}" data-parsley-error-message="${pageTranslations.validationError.phone}" data-parsley-pattern="${phonePattern}" data-parsley-trigger="focusout" data-val="true" data-val-maxlength-max="${phoneNumberMaxLength}" maxlength="${phoneNumberMaxLength}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7">
                <div class="form-element">
                    <div class="fl-wrap fl-wrap-input fl-is-active">
                        <div class="form-element__input-container">
                            <label class="form-element__label fl-label" for="Persons_${count}__Email">${pageTranslations.email}</label>
                            <input class="form-element__input fl-input" id="Persons_${count}__Email" name="Persons[${count}].Email" type="email" value="" placeholder="${pageTranslations.email}" data-parsley-error-message="${pageTranslations.validationError.email}" data-parsley-trigger="focusout" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>`);
        container.before(contentToAdd);
        window.buildFloatLabels();
        return false;
    })

    let numberOfTabs = 4;
    for (let i = 1; i <= numberOfTabs; i++) {
        $('#jsCompanyFormTab' + i).parsley({
            errorClass: '',
            successClass: ''
        });

        $('#saveForLater' + i).on('click', function (e) {
            e.preventDefault();
            saveForLater('jsCompanyFormTab' + i);
            return false;
        });

        $('#backToStep' + i).on('click', function (e) {
            e.preventDefault();
            goToPreviousStep(i);
            return false;
        })

        $('#goToStep' + (i + 1)).on('click', function (e) {
            e.preventDefault();
            goToStep((i + 1));
            return false;
        })
    }
});